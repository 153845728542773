export default class NiceI18nTranslationsGr {
  constructor() {
    return {
      addresses: {
        prompt_to_delete: "Είστε σίγουρος/η ότι θέλετε να διαγράψετε τη συγκεκριμένη διεύθυνση;",
        delete: "Διαγραφή",
        cancel_destroy: "Ακύρωση"
      },
      orders: {
        address: {
          select_address: "Επιλέξτε Διεύθυνση",
          text_select_region: '-- Επιλέξτε Νομό / Περιοχή --',
          text_select_country: '-- Επιλέξτε Χώρα --'
        }
      },
      flashes: {
        line_item_max_quantity_reached: "H ποσότητα που έχετε επιλέξει είναι μεγαλύτερη από τη διαθέσιμη. Παρακαλούμε επιλέξτε μικρότερη ποσότητα",
        not_available_quantity_for_product: "Μη διαθέσιμη ποσότητα για το προϊόν"
      },
      activerecord: {
        errors: {
          models: {
            address: {
              attributes: {
                country: {
                  blank: "Παρακαλούμε επιλέξετε την χώρα"
                },
                region: {
                  blank: "Παρακαλούμε επιλέξετε τον νομό"
                },
                vat_number: {
                  invalid: "Παρακαλούμε συμπληρώστε έναν έγκυρο ΑΦΜ"
                },
                vat_office_id: {
                  blank: "Παρακαλούμε συμπληρώστε τη ΔΟΥ σας"
                },
                zip: {
                  blank: "Παρακαλούμε συμπληρώστε το Τ.Κ."
                }
              }
            },
            line_item: {
              attributes: {
                option: {
                  required: "Το πεδίο αυτό ειναι υποχρεωτικό",
                  email_error: "Παρακαλούμε συμπληρώστε ένα έγκυρο e-mail"
                }
              }
            }
          }
        }
      },
      gift_lists: {
        edit_cart: "Επεξεργασία Καλαθιού",
        delete: "Διαγραφή",
        cancel_destroy: "Ακύρωση",
        prompt_to_delete: "Είστε σίγουρος/η ότι θέλετε να διαγράψετε τη συγκεκριμένη λίστα;",
        items: {
          prompt_to_delete: "Είστε σίγουρος/η ότι θέλετε να διαγράψετε το προϊόν από την λίστα;"
        },
        url: {
          share_again: "Μοιραστείτε τη λίστα Ξανά",
          share: "Μοιραστείτε την λίστα"
        },
        show_giftlists: "Εμφανίστε τις λίστες",
        hide_giftlists: "Κρύψτε τις λίστες"
      },
      shared: {
        site_selector_popup: {
          choose_country: "ΕΠΙΛΟΓΗ ΧΩΡΑΣ / CHOOSE COUNTRY",
          choose_language: "ΕΠΙΛΟΓΗ ΓΛΩΣΣΑΣ / CHOOSE LANGUAGE"
        },
        header: {
          search:{
            text_search: "Αναζήτηση προϊόντων...",
            no_data: "Δε βρέθηκαν αποτελέσματα...",
            all_results: "Δείτε όλα τα αποτελέσματα",
            not_enough_letters: {
              part_1: "Παρακαλούμε εισάγετε τουλάχιστον",
              part_2: "χαρακτήρες..."
            }
          }
        }
      },
      gdpr: {
        cookies: {
          popup: {
            update_preferences: "Ανανεώστε τις Ρυθμίσεις Cookies"
          }
        }
      },
      pay_modal: {
        card: {
          card_holder: "Card Holder",
          expires: "Expires",
          mm: "MM",
          yy: "YY"
        },
        card_form: {
          card_number: "Αριθμός Κάρτας",
          card_name: "Ονοματεπώνυμο",
          expiration_date: "Ημερομηνία Λήξης",
          month: "Μήνας",
          year: "Έτος",
          cvv: "CVC",
          submit: "ΠΛΗΡΩΜΗ",
          payment_approved: "ΠΛΗΡΩΜΗ ΕΠΙΤΥΧΗΣ",
          payment_not_approved: "ΠΛΗΡΩΜΗ ΜΗ ΑΠΟΔΕΚΤΗ",
          message_for_declined_payment: "Δυστυχώς η κάρτα σας δεν έγινε δεκτή με μήνυμα:"
        },
        errors: {
          transaction: "Παρουσίαστηκε σφάλμα κατά τη συναλλαγή σας: "
        }
      },
      saved_orders: {
        saved_line_item: {
          already_exists: "Το προϊόν δεν προστέθηκε καθώς υπάρχει ήδη"
        },
        saved_order: {
          no_line_items_on_save: "Μη επιτρεπτή ενέργεια. Η παραγγελία έχει μηδέν προϊόντα.",
          add_to_cart_prompt: "Μόνο οι αποθηκευμένες αλλαγές θα προστεθούν στο καλάθι. Θέλετε να προχωρήσετε;",
          prompt_to_delete: "Είστε σίγουρος ότι θέλετε να διαγράψετε την παραγγελία αυτή;",
          default_title: "Χωρίς όνομα"
        },
        product_select: {
          placeholder: "Αναζητήστε προϊόν...",
          no_products_found: "Δε βρέθηκαν προϊόντα...",
          empty_list: "Η λίστα είναι άδεια...",
          selected_label: "",
          deselected_label: "Απο-επιλέξτε",
          limit_text: {
            and: "και"
          }
        }
      }
    }
  }
}